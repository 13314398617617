import React from 'react';
import { useErrorReporter } from '@peloton/error-reporting';
import { theUserIsInAustralia } from '@peloton/internationalize';
import { useLocale } from '@peloton/next/hooks/useLocale';
import NextNoIndexMetadataTag from '@ecomm/metadata/NextNoIndexMetadataTag';
import { ErrorBoundary, ErrorMessage } from '@page-builder/layout/error-handling';
import type {
  TypeComponentLegalText,
  TypePage_marketingPageFields,
  TypeVariation,
} from '@page-builder/lib/types';
import Headband from '@page-builder/modules/Headband';
import LeadGenModalOrVariation from '@page-builder/modules/LeadGenModal/LeadGenModalOrVariation';
import LegalText from '@page-builder/modules/LegalText';
import ContainerWithLastChildOverRide from './ContainerWithLastChildOverride';
import type { PageHandler } from './types';

const REFURB_MARKETING_PAGE_NAME = 'Refurbished - Marketing Page';
type Props = PageHandler<TypePage_marketingPageFields>;

const MarketingPage: React.FC<React.PropsWithChildren<Props>> = ({
  headband,
  modal,
  hideModal,
  modules,
  legalText = [],
  block: Block,
  name,
}) => {
  const { errorReporter } = useErrorReporter();
  const locale = useLocale();
  //Refurbished in AU will be inside sales only and we want to hide it from SEO index
  const hidePage = theUserIsInAustralia(locale) && name == REFURB_MARKETING_PAGE_NAME;
  return (
    <>
      {hidePage && <NextNoIndexMetadataTag />}
      {headband && <Headband {...headband} />}
      {!hideModal && modal && <LeadGenModalOrVariation {...modal} />}
      <ContainerWithLastChildOverRide>
        {modules
          ? modules.map((module, i) => (
              <ErrorBoundary
                key={`module=${i}`}
                tags={{ component: 'pb-component', entryId: module?.sys?.id }}
                reportError={errorReporter.reportError}
                renderError={({ error }) => (
                  <ErrorMessage error={error} module={module} />
                )}
              >
                <Block block={module} index={i} />
              </ErrorBoundary>
            ))
          : null}
      </ContainerWithLastChildOverRide>
      {Boolean(legalText.length) && (
        <LegalText legalText={legalText as (TypeComponentLegalText | TypeVariation)[]} />
      )}
    </>
  );
};

export default MarketingPage;
