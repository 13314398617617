import Head from 'next/head';
import React from 'react';

const NextNoIndexMetadataTag: React.FC<React.PropsWithChildren<unknown>> = () => (
  <Head>
    <meta name="robots" content="noindex" />
  </Head>
);

export default NextNoIndexMetadataTag;
